import type Logger from "bunyan"
import { Blog, BlogRecommendation } from "@types"
import { getCollectibleChain } from "../crypto"
import { CHAINS } from "@/types/highlights"
import { FramePostWithJson, MetaTag } from "./types"
import { FrameURLs } from "./urls"

export class FrameButtons {
  /**
   * The logger object.
   */
  private logger: Logger

  /**
   * The blog object.
   */
  private blog: Blog

  /**
   * The buttons class has dependencies on the URLs class.
   */
  private urls: FrameURLs

  /**
   * The post object. Must contain some minimum set of fields (see the type).
   * This is an optional field because if the frame was generated for a blog and not a post, this field will be undefined.
   */
  private post?: FramePostWithJson

  constructor({
    urls,
    logger,
    blog,
    post,
  }: {
    urls: FrameURLs
    logger: Logger
    blog: Blog
    post?: FramePostWithJson
  }) {
    this.urls = urls

    this.logger = logger
    this.blog = blog
    this.post = post
  }

  /*****--------------------*****/
  /*****   BUTTON METHODS   *****/
  /*****--------------------*****/

  /**
   * Creates the "back" button for the frame.
   * @param idx The order of the button. Index starts with 1.
   * @returns Returns an array of meta tags for the "back" button.
   */
  back(idx: number, currentPage?: number): MetaTag[] {
    return [
      { property: `button:${idx}`, content: "<" },
      // Although Farcaster frames default to this, XMTP Open Frames do not, so we need to specify this or the button won't work.
      {
        property: `button:${idx}:action`,
        content: "post",
      },
      {
        property: `button:${idx}:post_url`,
        content: this.urls.readPostBack(currentPage),
      },
    ]
  }

  /**
   * Creates the "forward" button for the frame.
   * @param idx The order of the button. Index starts with 1.
   * @returns Returns an array of meta tags for the "forward" button.
   */
  forward(idx: number, currentPage: number): MetaTag[] {
    return [
      { property: `button:${idx}`, content: ">" },
      // Although Farcaster frames default to this, XMTP Open Frames do not, so we need to specify this or the button won't work.
      {
        property: `button:${idx}:action`,
        content: "post",
      },
      {
        property: `button:${idx}:post_url`,
        content: this.urls.readPostNext(currentPage),
      },
    ]
  }

  /**
   * Creates the "skip" button for the frame.
   * @param idx The order of the button. Index starts with 1.
   * @returns Returns an array of meta tags for the "skip" button.
   */
  skip(idx: number): MetaTag[] {
    return [
      { property: `button:${idx}`, content: "Skip" },
      // Although Farcaster frames default to this, XMTP Open Frames do not, so we need to specify this or the button won't work.
      {
        property: `button:${idx}:action`,
        content: "post",
      },
      {
        property: `button:${idx}:post_url`,
        content: this.urls.subscribeSkipEmail(), // Skip email input.
      },
    ]
  }

  /**
   * Creates the "read online" button for the frame.
   * @param idx The order of the button. Index starts with 1.
   * @returns Returns an array of meta tags for the "read online" button.
   */
  openPost(idx: number): MetaTag[] {
    return [
      { property: `button:${idx}`, content: "Open" },
      { property: `button:${idx}:action`, content: "post_redirect" },
      // This doesn't appear to have any effect on post_redirect. When clicking post_redirect
      // the user is redirected to the post_url on the frame, not the button, despite what the
      // documentation says to the contrary.
      { property: `button:${idx}:post_url`, content: this.urls.openPost() },
    ]
  }

  /**
   * Creates the "read inline" button for the frame.
   * @param idx The order of the button. Index starts with 1.
   * @param isPostLevelGate Whether the post is post-level gated.
   * @returns Returns an array of meta tags for the "read inline" button.
   */
  readInline(idx: number, isPostLevelGate: boolean): MetaTag[] {
    if (!this.shouldDisplayButtonReadInline(isPostLevelGate)) return []

    return [
      { property: `button:${idx}`, content: "Read Inline" },
      // Although Farcaster frames default to this, XMTP Open Frames do not, so we need to specify this or the button won't work.
      {
        property: `button:${idx}:action`,
        content: "post",
      },
      {
        property: `button:${idx}:post_url`,
        content: this.urls.readPostPage(0), // Start on the first page.
      },
    ]
  }

  /**
   * Creates the "subscribe" button for the frame which subscribes a user with wallet address,
   * and optionally an email address.
   * @param idx The order of the button. Index starts with 1.
   * @returns Returns an array of meta tags for the "subscribe" button.
   */
  subscribe(idx: number): MetaTag[] {
    return [
      { property: `button:${idx}`, content: "Subscribe 🔔" },
      // Although Farcaster frames default to this, XMTP Open Frames do not, so we need to specify this or the button won't work.
      {
        property: `button:${idx}:action`,
        content: "post",
      },
      {
        property: `button:${idx}:post_url`,
        content: this.urls.subscribe(),
      },
    ]
  }

  /**
   * Creates the "subscribe" button for the frame which subscribes a user with email address.
   * @param idx The order of the button. Index starts with 1.
   * @returns Returns an array of meta tags for the "subscribe" button.
   */
  subscribeWithEmail(idx: number): MetaTag[] {
    return [
      { property: `button:${idx}`, content: "Subscribe 🔔" },
      // Although Farcaster frames default to this, XMTP Open Frames do not, so we need to specify this or the button won't work.
      {
        property: `button:${idx}:action`,
        content: "post",
      },
      {
        property: `button:${idx}:post_url`,
        content: this.urls.subscribeWithEmail(),
      },
    ]
  }

  /**
   * Creates the "subscribe" button for the frame which subscribes a user to a specific blog recommendation.
   * @param idx The order of the button. Index starts with 1.
   * @param rec The blog recommendation object.
   * @returns Returns an array of meta tags for the "subscribe" button.
   */
  subscribeRecommendation(idx: number, rec: BlogRecommendation): MetaTag[] {
    return [
      { property: `button:${idx}`, content: `🔔 ${rec.name}` },
      // Although Farcaster frames default to this, XMTP Open Frames do not, so we need to specify this or the button won't work.
      {
        property: `button:${idx}:action`,
        content: "post",
      },
      {
        property: `button:${idx}:post_url`,
        content: this.urls.subscribeToRecommendations(rec.id),
      },
    ]
  }

  /**
   * Creates a generic button with text and an optional URL.
   * @param idx The order of the button. Index starts with 1.
   * @param text The text to display on the button.
   * @param url The URL to link to when the button is clicked.
   * @returns Returns an array of meta tags for the generic button.
   */
  genericButton(idx: number, text: string, url?: string): MetaTag[] {
    const response = [{ property: `button:${idx}`, content: text }]

    if (url) {
      response.push(
        ...[
          // Although Farcaster frames default to this, XMTP Open Frames do not, so we need to specify this or the button won't work.
          {
            property: `button:${idx}:action`,
            content: "post",
          },
          {
            property: `button:${idx}:post_url`,
            content: url,
          },
        ]
      )
    }

    return response
  }

  /**
   * Creates a generic button that opens a post.
   * @param idx The order of the button. Index starts with 1.
   * @param text The text to display on the button.
   * @returns Returns an array of meta tags for the generic button that opens a post.
   */
  genericButtonThatOpensPost(idx: number, text: string): MetaTag[] {
    return [
      { property: `button:${idx}`, content: text },
      { property: `button:${idx}:action`, content: "post_redirect" },
      // This doesn't appear to have any effect on post_redirect. When clicking post_redirect
      // the user is redirected to the post_url on the frame, not the button, despite what the
      // documentation says to the contrary.
      { property: `button:${idx}:post_url`, content: this.urls.openPost() },
    ]
  }

  /**
   * Creates a generic share button with text and a URL.
   * @param idx The order of the button. Index starts with 1.
   * @param text The text to display on the button.
   * @param url The URL to link to when the button is clicked.
   * @returns Returns an array of meta tags for the generic button.
   */
  genericShareButton(idx: number, text: string, url: string): MetaTag[] {
    const response = [{ property: `button:${idx}`, content: text }]

    if (url) {
      response.push(
        {
          property: `button:${idx}:action`,
          content: "link",
        },
        {
          property: `button:${idx}:target`,
          content: url,
        }
      )
    }

    return response
  }

  /**
   * This returns the mint buttons, which if clicked would allow the user to mint with warps, or with crypto, etc.
   * @param idx The order of the button. Index starts with 1.
   * @returns An array of meta tags for the mint buttons.
   */
  mint(idx: number): MetaTag[] {
    if (!this.post) {
      this.logger.error(
        "Cannot generate mint button because the post is undefined."
      )

      return []
    }

    if (!this.shouldDisplayButtonMint()) return []

    return [
      { property: `button:${idx}`, content: "Mint" },
      {
        property: `button:${idx}:action`,
        content: "post",
      },
      {
        property: `button:${idx}:target`,
        content: this.urls.mintButtons(),
      },
    ]
  }

  /**
   * This initiates the actual mint transaction.
   * @param idx The order of the button. Index starts with 1.
   * @returns An array of meta tags for the mint with crypto button.
   */
  mintWithCrypto(idx: number): MetaTag[] {
    if (!this.post) {
      this.logger.error(
        "Cannot generate mint with crypto button because the post is undefined."
      )

      return []
    }

    if (!this.shouldDisplayButtonMint()) return []

    return [
      { property: `button:${idx}`, content: "Mint with Crypto" },
      {
        property: `button:${idx}:action`,
        content: "tx",
      },
      {
        property: `button:${idx}:target`,
        content: this.urls.mint(),
      },
      // Callback that gets called after the transaction is complete.
      // This can either be a success or a failure, the mintCallback URL will handle both.
      {
        property: `button:${idx}:post_url`,
        content: this.urls.mintCallback(),
      },
    ]
  }

  /**
   * This initiates the actual mint transaction to mint with Farcaster warps.
   * @param idx The order of the button. Index starts with 1.
   * @param chainId The chain ID of the collectible.
   * @param contractAddress The contract address of the collectible.
   * @returns An array of meta tags for the mint with warps button.
   */
  mintWithWarps(
    idx: number,
    chainId: number,
    contractAddress: string
  ): MetaTag[] {
    if (!this.post) {
      this.logger.error(
        "Cannot generate mint with warps button because the post is undefined."
      )

      return []
    }

    if (!this.shouldDisplayButtonMint()) return []

    return [
      { property: `button:${idx}`, content: "Mint with Warps" },
      {
        property: `button:${idx}:action`,
        content: "mint",
      },
      {
        property: `button:${idx}:target`,
        content: `eip155:${chainId}:${contractAddress}`,
      },
    ]
  }

  /*****------------------------------*****/
  /*****   CONDITIONAL BUTTON LOGIC   *****/
  /*****------------------------------*****/

  /**
   * Determines whether the "read inline" button should be displayed.
   *
   * Only display the "read inline" button if the post is not post-level gated.
   * @param isPostLevelGate Whether the post is post-level gated. If true, the "read inline" button will not be displayed.
   * @returns A boolean indicating whether the "read inline" button should be displayed.
   */
  private shouldDisplayButtonReadInline(isPostLevelGate: boolean): boolean {
    return !isPostLevelGate
  }

  /**
   * Determines whether the "mint" button should be displayed.
   *
   * Does not display the mint button if collectibles are disabled for the note or for the blog.
   * @returns A boolean indicating whether the "mint" button should be displayed.
   */
  private shouldDisplayButtonMint(): boolean {
    if (!this.post) {
      return false
    }

    if (this.post.collectiblesDisabled || this.blog.disable_highlights) {
      return false
    }

    const chain = getCollectibleChain(this.post, this.blog)

    const MINT_WITH_WARPS_CHAINS: Array<CHAINS> = ["base", "zora"]
    const OTHER_CHAINS: Array<CHAINS> = ["optimism"]
    const SUPPORTED_CHAINS: Array<CHAINS> = [
      ...MINT_WITH_WARPS_CHAINS,
      ...OTHER_CHAINS,
    ]

    if (!SUPPORTED_CHAINS.includes(chain)) return false

    return true
  }
}
