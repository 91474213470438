import { Note } from "@types"
import clsx from "clsx"

import { useEffect, useState } from "react"
import TipTapEditor from "components/Editor/TipTapEditor"
import { getHtml } from "util/tiptap"
import { postClassNames } from "@/util/constants/style"
import { Editor } from "@tiptap/react"

export default function PostContentEditor({
  editor,
  currentNote,
  editorReady,
  initialContent,
}: {
  editor: Editor | null
  currentNote: Note
  editorReady: boolean
  initialContent: string
}) {
  const [clientSide, setClientSide] = useState(false)

  const html = currentNote.staticHtml || getHtml(initialContent) || ""

  useEffect(() => {
    setClientSide(true)
  }, [])

  if (clientSide && editor && editorReady) {
    return <TipTapEditor editor={editor} />
  }

  return (
    <div
      className={clsx(postClassNames, "tiptap ProseMirror")}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  )
}
