import { useSelector } from "react-redux"
import Link from "next/link"
import { selectUsersBlog } from "features/blogSlice"
import { Menu, Transition } from "@headlessui/react"
import { useAnalytics } from "hooks/useAnalytics"
import { Fragment, useEffect } from "react"
import useUser from "hooks/useUser"
import Button from "components/Button"

import { getLoggedInUsersBlog } from "features/blogSlice"
import UserAvatar from "components/UserAvatar"

import useWalletName from "hooks/useWalletName"
import { useAppDispatch } from "store"

/*
 * showExtraLinks is for displaying links
 * to bring the user back to the dashboard. This should be visibile
 * on non-Dashboard pages.
 */
export default function AvatarDropdown(props: { showExtraLinks?: boolean }) {
  const user = useUser()
  const blog = useSelector(selectUsersBlog)

  const dispatch = useAppDispatch()
  const { track } = useAnalytics()

  const walletName = useWalletName(user.cryptoData?.address?.toString() || "")

  useEffect(() => {
    if (!user.loggedIn || blog?.id) return
    dispatch(getLoggedInUsersBlog())
  }, [dispatch, blog.id, user.loggedIn])

  if (!user.loggedIn) return null

  const userIdentifier =
    "usernameUnstoppable" in user && user.usernameUnstoppable
      ? user.usernameUnstoppable
      : "email" in user && user.email
      ? user.email
      : walletName.type == "ENS"
      ? walletName.name
      : "wallet_address" in user && user.wallet_address
      ? user.wallet_address
      : null

  const avatarType = user.avatar_url ? "user" : blog.logo_url ? "blog" : null

  return (
    <Menu
      as="div"
      className="relative z-20"
      onClick={() => track("avatar dropdown click")}
    >
      <Menu.Button className="flex items-center max-w-xs text-sm rounded-full z-20 text-gray-500 hover:text-gray-300 transition-all w-[36px] h-auto">
        <span className="sr-only">Open user menu</span>
        <UserAvatar avatarType={avatarType} url={user.avatar_url} noSSR />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute truncate overflow-ellipsis right-0 max-w-md py-1 mt-2 bg-white dark:bg-gray-700 shadow-lg origin-top-right rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
          <Menu.Item>
            <span className="block px-4 py-2 text-xs text-gray-400 border-b border-gray-100 dark:text-gray-200 dark:border-gray-500">
              Signed in as <br />
              <span className="break-all font-bold text-gray-600 dark:text-white">
                {userIdentifier}
              </span>
            </span>
          </Menu.Item>

          {props.showExtraLinks && (
            <Menu.Item as={Link} href="/notes">
              {({ close }) => (
                <span
                  onClick={close}
                  className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  Dashboard
                </span>
              )}
            </Menu.Item>
          )}

          <Menu.Item as={Link} href="/settings/account/account">
            {({ close }) => (
              <span
                onClick={close}
                className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                Account Settings
              </span>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ close }) => (
              <a
                href="https://docs.paragraph.xyz"
                target="_blank"
                rel="noreferrer"
                onClick={close}
              >
                <span className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-600">
                  Help & Documentation
                </span>
              </a>
            )}
          </Menu.Item>
          <Menu.Item>
            <Button
              onClick={async () => {
                await user.signoutUser()
                window.location.href = "/"
              }}
              replaceClassName="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-600 w-full text-left"
              text="Sign out"
            />
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
