import { Blog, BlogRecommendation } from "@types"
import {
  RecommendationBlogParams,
  RecommendationParams,
} from "@/types/farcaster"
import { getShareImage } from "../format"
import { baseDomain } from "./helpers"
import { FrameUser } from "./types"

export class FrameImages {
  /**
   * The blog object.
   */
  private blog: Blog

  constructor({ blog }: { blog: Blog }) {
    this.blog = blog
  }

  /**
   * Generates the recommendation share image for a given frame.
   */
  getRecommendationImage({
    recommendations,
    recommendingBlog,
  }: {
    recommendations: BlogRecommendation[]
    recommendingBlog: Partial<Blog>
  }): string {
    const smallerBlog: RecommendationBlogParams = {
      name: recommendingBlog.name || "",
    }

    const smallerRecs: RecommendationParams[] = recommendations.map((rec) => {
      return {
        name: rec.name,
        logo_url: rec.logo_url,
        summary:
          rec.reason === "Subscribed!"
            ? "You're already subscribed!"
            : rec.summary,
        reason: rec.reason,
      }
    })

    const encodedRecs = encodeURIComponent(JSON.stringify(smallerRecs) || "[]")
    const blog = encodeURIComponent(JSON.stringify(smallerBlog) || "{}")

    const domain = baseDomain()
    const share_img = `${domain}/api/recommendations?recommendations=${encodedRecs}&blog=${blog}`
    return share_img
  }

  /**
   * Gets the blog-level (NOT post-level) share image for a frame.
   */
  populateShareImage(description: string, blogOwnerUser?: FrameUser) {
    let frame_image = this.blog.logo_url //|| "https://paragraph.xyz/share/share_img.jpg"

    // If the blog has no URL but the user has an avatar, fall back to the user's avatar.
    if (!this.blog.logo_url && blogOwnerUser && blogOwnerUser.avatar_url) {
      frame_image = blogOwnerUser.avatar_url
    }

    const share_img = getShareImage({
      image: frame_image,
      title: this.blog.name || "",
      description,
      color: this.blog.primary_color || "",
      showSubscribeBtn: false,
    })

    return share_img
  }
}
