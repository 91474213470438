/**
 * Useful if you need to pass in a console logger when running scripts that don't have a real Bunyan logger.
 */
const consoleLogger = {
  trace: console.trace,
  debug: console.debug,
  info: console.log,
  warn: console.warn,
  error: console.error,
  fatal: (message?: any, ...optionalParams: any[]) => {
    console.error("FATAL:", message, ...optionalParams)
  },
  child: (_options?: object) => {
    return consoleLogger
  },
} as any

export default consoleLogger
