import { jsonFromMarkdownText } from "util/markdown"
import { useState } from "react"
import useCustomEditor from "hooks/useCustomEditor"

function objIsEmpty(obj: any) {
  return obj === undefined || !obj || (obj && Object.keys(obj).length === 0)
}

export default function useEditorWrapper(json?: string, text?: string) {
  const [editorReady, setEditorReady] = useState(false)

  const initialContent =
    (!objIsEmpty(json) ? json : jsonFromMarkdownText(text || "")) || ""

  const { editor } = useCustomEditor({
    contentEditable: false,
    initialContent,
    onCreate: () => setEditorReady(true),
  })

  return { editor, editorReady, initialContent }
}
